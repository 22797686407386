import vffs from "../../images/packaging/VFFS.png";
import hffs from "../../images/packaging/HFFS.png";
import trayKnife from "../../images/packaging/Tray Seal.png";
import vacuumKnife from "../../images/packaging/Vacuum Thermoformed Blister.png";

function Packaging() {
  return (
    <>
      <section>
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Packaging</h1>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <div className="col-10 col-md-9 text">
            <p>
              Industrial Knife Warehouse prides itself on providing high quality knives, technical expertise and fast on time delivery when it comes to knives for the packaging industry.  We specialize in Vertical Form Fill Seal (VFFS) knives, Horizontal Form Fill Seal (HFFS) knives, tray and cup seal  knives and vacuum, thermoformed and blister packaging knives as well as any circular knives that are needed.  All knives are manufactured using the greatest care, creating the sharpest edges available for extended wear and quality of cut product.
            </p>
          </div>
        </div>
      </section>
      <br/>

      <section className="vertical-form-fill-seal">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Vertical Form Fill Seal</h3>
            <br/>
            <p>
              Industrial Knife Warehouse can supply serrated, scalloped, zigzag or EZ-open tooth profiles for your VFFS knives.  We can make the blades with rivets or slotted guides to fit any machine and we offer tear notches or material upgrades and coatings to meet your requirements.
            </p>
            <p>
              Machines:
            </p>
            <p>
              ACMA Wright, Apex, Bosch, Cryovac, General Packaging, Hart, Hayssen, Heat & Control, Ilapak, Ishida, Masipack, Matrix, Mirapak, PacMac, Rose Forgrove, Rovema, Sandiacre, TNA, Triangle
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=VFFS%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={vffs}
              alt=""
              className="col-12 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>

      <section className="horizontal-form-fill-seal">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Horizontal Form Fill Seal</h3>
            <br/>
            <p>
              Industrial Knife Warehouse manufacturers knives for flow-wrapping, shrink-wrapping, overwrapping, diamond wrapping and horizontally fed pouch filling machinery.  Whether you need knives for low-speed or high-speed machinery, all our knives are made out of quality materials with the option of customized coatings for longer wear resistance.
            </p>
            <p>
              Machines:
            </p>
            <p>
              ACMA GD, Allpak, Autowrapper, Bartelt, Bassar, Bosch, Carle Montanari, Cavanna, Doboy, Campbell, Delta, George Gordon, Gram Equipment, Ideal, Eurosicma, FMC, Fuji, HMC, Ilapak, Kallfass, Klockner, Loesch, Marden Edwards, Maripak, Nortech, Ossid, PAC Machinery, Proto-Pak, Sigpack, Scnadia, Sealed Air, Shanklin, Sig/Sasib, Theegarten, Tevopharm, TNA Robag, ULMA, Viking Masek, WeighPack
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=HFFS%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={hffs}
              alt=""
              className="col-12 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>

      <section className="tray-and-cup-seal">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Tray and Cup Seal Knives</h3>
            <br/>
            <p>
              Industrial Knife Warehouse uses high precision CNC formers for exact form and fit qualities for all tray and cup sealing knives.  Whether you need double bay, 4-bay or 5-bay, we can configure your knives to your exact specifications.  With some of the shortest lead times in the industry, many companies have come to rely on Industrial Knife Warehouse for their tray and cup seal knives.
            </p>
            <p>
              Machines:
            </p>
            <p>
              All-Fill, Arpac, Autoprod, Ampak, Comet Machine, Control GMC, Demaco, Faith Engineering, Fill-Tech, GEA, Haug Manufacturing, ICA, IMA, Ilpra, Ishida, Maptech, Modern, Mondini, Multivac, Opem, Optima, Oric, Osgood, Packline, Pactec, Rocket Manufacturing, Ross, Rotary Concepts, Ultrasource, Volumetric, World Cup, Zitropack
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Tray%20Seal%20Knife%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={trayKnife}
              alt=""
              className="col-12 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>

      <section className="vacuum-thermoformed-and-blister">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Vacuum, Thermoformed & Blister Packaging</h3>
            <br/>
            <p>
              Vacuum, thermoformed and blister packaging are some of the hardest materials to cut due to the thickness.  Industrial Knife Warehouse has the expertise to be able to determine what material, hardness and tooth profile or angle is needed to cut through your most difficult materials.
            </p>
            <p>
              Machines:
            </p>
            <p>
              Colimatic, CP Packaging, Cryovac, Dixievac, GEA, Harpak, Hayssen, Henkelman, Hooper, JVR Industries, Masipack, Multivac, Optima, Ossid, Rapidpak, Robert Reiser, Rollstock, Supervac, TDI Packsys, Tetra Laval, Tiromat, ULMA, Ultravac, VC999
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=VFFS%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={vacuumKnife}
              alt=""
              className="col-12 mb-3"
            >
            </img>

          </div>
        </div>
      </section>
      <br/>

      <br/>
      <br/>
    </>
  )
}

export default Packaging;