import firstGranulator from "../../images/recycling/granulators-1.png";
import thirdGranulator from "../../images/recycling/granulators-3.png";
import shredderAnvil from "../../images/recycling/granulators-2.png";
import shredder from "../../images/recycling/granulators-4.png"


function Recycling() {
  return (
    <>
      <section>
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Recycling</h1>
        </div>
        <br/>
        <div className="col text-secondary">
          <div className="d-flex justify-content-center">
            <p className="col-10 col-md-9">
              Industrial Knife Warehouse can supply numerous types of knives for the recycling industry.  We supply granulators and pelletizing knives for the plastic recycling industry, straight knives and shredders for the carpet recycling industry and many knives for the tire recycling industry.  All knives are made to exceed OEM specifications and depending on your application, we can use different types of materials to maximize your production.
            </p>
          </div>
        </div>
      </section>
      <br/>

      <section className="granulators">
        <div className="d-flex justify-content-center align-items-center">
          <h3 className="header-text col-10 col-md-9">Granulators & Pelletizers</h3>
        </div>
        <br/>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="g-3 col-10 col-md-3">
            <p className="text col-10 col-md-12">
              Brands we supply include, but are not limited to:
            </p>
            <p className="text col-10 col-md-12">
              Alsteele, Ball & Jewell, Beringer, BKG, CMG, Conair, Cumberland, Erema, Gala, Granutech, Herbold, MPG, Nelmor, NGR, Polymer Systems, Rapid, Rotogrand, Tria, Wortex
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Granulator%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <div className="row col-10 col-md-6">
            <img
              src={firstGranulator}
              alt="Two granulator blades with straight edges."
              className="col-12 col-md-6 mb-3"
            >
            </img>
            <img
              src={thirdGranulator}
              alt="Several pelletizer blades"
              className="col-12 col-md-6 mb-3"
            >
            </img>
          </div>
          <br/>
        </div>
      </section>
      <br/>
      <section className="shredders">
        <div className="d-flex justify-content-center align-items-center">
          <h3 className="header-text col-10 col-md-9">Shredders</h3>
        </div>
        <br/>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="g-3 col-10 col-md-3">
            <p className="text col-10 col-md-12">
              Brands we supply include, but are not limited to: 
            </p>
            <p className="text col-10 col-md-12">
              Advanced Recycling, Ball & Jewell, Colortronic, Creswood, Cumberland, Econogrind, Erema, Firefox, Lindner, Marathon, Nelmor, NGR, Powercat, Rapid, Redoma, Republic, Retech, SSI, Vecoplan, Weima, Zerma
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Shredder%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <div className="row col-10 col-md-6">
            <img
              src={shredder}
              alt="A shredder blade."
              className="col-12 col-md-6 mb-3"
            >
            </img>
            <img
              src={shredderAnvil}
              alt="Two shredder anvils."
              className="col-12 col-md-6 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <section className="tire-shredders">
        <div className="d-flex justify-content-center align-items-center">
          <h3 className="header-text col-10 col-md-9">Tire Recycling</h3>
        </div>
        <br/>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="g-3 col-10 col-md-9">
            <p className="text col-10 col-md-12">
              Brands we supply include, but are not limited to: Barclay, Columbus McKinnon, Eco, Green, Eldan-Rasper, Granutech, Predator
            </p>
          </div>
          <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Tire%20Shredder%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
        </div>
      </section>
      <br/>
      <br/>
    </>
  )
}

export default Recycling;