import React, { useState, useRef } from "react";
import { NavLink, Link  } from "react-router-dom";
import "./TopNav.css";
import useClickOutside from "../utils/useClickOutside";

/**
 * Defines the menu for this application.
 *
 * @returns {JSX.Element}
 */

function TopNav() {

  /**
   * Close dropdown when user clicks outside of menu
   */
  const [dropdown, setDropdown] = useState(false);
  const wrapperRef = useRef("menu");
  useClickOutside(wrapperRef, () => {
    setDropdown(false);
  });


  return (
    <nav className="navbar navbar-light navbar-expand-md align-items-start p-0 my-2">
      <div className="container-fluid d-flex flex-column p-0 my-2">
        <hr className="my-0" />
        <ul className="nav navbar-nav " id="topNav">
          <li className="nav-item mx-3">
            <NavLink
              className="nav-link link-offset-3"
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item mx-3">
            <NavLink
              className="nav-link link-offset-3"
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li className="nav-item dropdown mx-3" >
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown(!dropdown)}
            >
              Industries Served
            </Link>
            <ul
              ref={wrapperRef}
              className={`dropdown-menu text ${dropdown ? "show" : ""}`}
              id="dropdown-menu"
            >
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/printing"
                  onClick={() => setDropdown(false)}
                >
                  Printing
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/paper-converting"
                  onClick={() => setDropdown(false)}
                >
                  Paper Converting
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/packaging"
                  onClick={() => setDropdown(false)}
                >
                  Packaging
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/carbide"
                  onClick={() => setDropdown(false)}
                >
                  Carbide
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/recycling"
                  onClick={() => setDropdown(false)}
                >
                  Recycling
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  id="dropdown-item"
                  to="/shear-blades"
                  onClick={() => setDropdown(false)}
                >
                  Shear Blades
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item mx-3">
            <NavLink
              className="nav-link link-offset-3"
              to="/contact"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <div className="text-center d-none d-md-inline">
          <button
            className="btn rounded-circle border-0"
            id="sidebarToggle"
            type="button"
          />
        </div>
      </div>
    </nav>
  );
}

export default TopNav;