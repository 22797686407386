import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home"
import About from "../pages/About"
import Printing from "../pages/industries/Printing"
import PaperConverting from "../pages/industries/PaperConverting"
import Packaging from "../pages/industries/Packaging"
import Carbide from "../pages/industries/Carbide"
import Recycling from "../pages/industries/Recycling"
import ShearBlades from "../pages/industries/ShearBlades"
import Contact from "../pages/Contact"
import NotFound from "./NotFound";

/**
 * Defines all the routes for the application.
 *
 * @returns {JSX.Element}
 */
function AllRoutes() {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="printing" element={<Printing />} />
      <Route path="paper-converting" element={<PaperConverting />} />
      <Route path="packaging" element={<Packaging />} />
      <Route path="carbide" element={<Carbide />} />
      <Route path="recycling" element={<Recycling />} />
      <Route path="shear-blades" element={<ShearBlades />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AllRoutes;