import tobacco1 from "../../images/carbide/tobacco/tobacco1.png";
import tobacco2 from "../../images/carbide/tobacco/tobacco2.png";
import tobacco3 from "../../images/carbide/tobacco/tobacco3.png";
import tobacco4 from "../../images/carbide/tobacco/tobacco4.png";
import tobacco5 from "../../images/carbide/tobacco/tobacco5.png";
import corrugated1 from "../../images/carbide/corrugated/corrugated1.png";
import corrugated2 from "../../images/carbide/corrugated/corrugated2.png";
import corrugated3 from "../../images/carbide/corrugated/corrugated3.png";
import corrugated4 from "../../images/carbide/corrugated/corrugated4.png";
import corrugated5 from "../../images/carbide/corrugated/corrugated5.png";
import printing1 from "../../images/carbide/printing/printing1.png";
import printing2 from "../../images/carbide/printing/printing2.png";
import printing3 from "../../images/carbide/printing/printing3.png";
import printing4 from "../../images/carbide/printing/printing4.png";
import printing5 from "../../images/carbide/printing/printing5.png";
import batteries1 from "../../images/carbide/batteries/Battery1.png";
import batteries2 from "../../images/carbide/batteries/Battery2.png";
import batteries3 from "../../images/carbide/batteries/Battery3.png";
import batteries4 from "../../images/carbide/batteries/Battery4.png";
import batteries5 from "../../images/carbide/batteries/Battery5.png";

function Carbide() {
  return (
    <>
      <section className="selling-points">
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Carbide</h1>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <div className="col-10 col-md-9 text">
            <p>
              Industrial Knife Warehouse’s cemented carbide represent a group of materials which differ from other hard materials such as ceramics, corundum or diamond in view of their metallic properties These metallic properties include for example good electrical and thermal conductivity.  Carbide is a powder-metallurgical two-phase material consisting of a hard material phase and a metal binder phase. The hard material provides the necessary hardness (abrasive resistance) and the binder metal guarantees appropriate toughness which allows us to manufacture your knives in accordance to what you need dictated by the your cutting application.  In the case of cemented carbides for cutting and wear protection tungsten carbide (WC) is generally used as a hard carbide phase and cobalt (Co) as metal binder (binder phase). The reason for this is that applying this combination the best physical and mechanical properties can be obtained.
            </p>
            <br/>
            <div className="row justify-content-around">
              <ul className="col-10 col-md-5">
                <p className="header-text fs-5">Relevant properties of cemented carbide:</p>
                <li>Wear resistance/hardness</li>
                <li>Comprehensive strength</li>
                <li>Impact strength</li>
                <li>Transverse rupture strength</li>
                <li>Corrosion resistance, resistance to oxidation</li>
                <li>Toughness</li>
              </ul>
              <ul className="col-10 col-md-5">
                <p className="header-text fs-5">Advantages of carbide:</p>
                <li>Greater abrasive resistance for longer edge life</li>
                <li>Resist high temperatures</li>
                <li>Most effective wherever cutting tools are exposed to high wear conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tobacco-industry">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Tobacco Industry</h3>
            <br/>
            <p>
              Industrial Knife Warehouse supplies top of the line slitters and cutoff knives made out of a new generation, fine grain carbide for the tobacco industry.  Many manufacturers of cigarettes, cigars, snuff, pipe tobacco and loose leaf chewing tobacco have come to rely on our expertise for their cutting solutions.  Our knives can meet the tight tolerances required for this industry.  With a high hardness along with the mirror finish on the cutting edge, these knives will have no problem cutting without any chipping which will allow for more production and less downtime.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Carbide%20Tobacco%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <div className="row g-1">
              <div className="col my-1">
                <img
                  src={tobacco1}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={tobacco2}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
              <div className="col my-1">
                <img
                  src={tobacco3}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={tobacco4}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
            </div>
            <img
              src={tobacco5}
              alt=""
              className="col-12 col-md-12 m-0 px-1"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="corrugated-industry">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Corrugated Industry</h3>
            <br/>
            <p>
              Industrial Knife Warehouse’s carbide razor slitters for the corrugated industry will last 10-15 times longer than the standard high speed steel blade.  Because of the fine grain carbide used to make these slitters and the mirror finish on the cutting edge, these slitters will have no problem providing a clean cut on low-medium speeds as well as high speed production lines.  We can also supply the grinding wheels that are used with these slitters.  Industrial Knife Warehouse has the original OEM drawings for machines such as Fosber, BHS, Agnati, Isowa, MHI and Marquip.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Tobbaco%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <div className="row g-1">
              <div className="col my-1">
                <img
                  src={corrugated1}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={corrugated2}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
              <div className="col my-1">
                <img
                  src={corrugated3}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={corrugated4}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
            </div>
            <img
              src={corrugated5}
              alt=""
              className="col-12 col-md-12 m-0 px-1"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="printing-industry">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Printing Industry</h3>
            <br/>
            <p>
              Industrial Knife Warehouse supplies different types of carbide knives for the printing industry.  Besides paper knives, we also supply milling knives, shredder knives and table cutter knives for large format printing.  As paper chemistries change to produce acid-free, alkaline papers that are significantly more abrasive, Industrial Knife Warehouse has worked with the top knife manufacturers to develope cutting tool materials and special designs to meet the challenge. Modern, high speed, labor saving binding and printing machines require special cutting tools to keep production running at full capacity. Our knives are recognized as the longest lasting in the industry.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Tobbaco%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <div className="row g-1">
              <div className="col my-1">
                <img
                  src={printing1}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={printing2}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
              <div className="col my-1">
                <img
                  src={printing3}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={printing4}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
            </div>
            <img
              src={printing5}
              alt=""
              className="col-12 col-md-12 m-0 px-1"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <section className="lithium-ion-battery-industry">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Lithium-ion Battery Industry</h3>
            <br/>
            <p>
              Industrial Knife Warehouse supplies high quality carbide knives for the lithium ion battery industry.  We supply many companies with electrode slitting knives, copper or aluminum foil slitting knives or film cutting cutoff knives.  These blades are made out of an ultra-fine grain carbide, have a high wear resistance and will have a high precision, very sharp cutting edge which will improve production drastically.  All of our slitting knives for this industry will be held to a tolerance of ±0.02mm on the outside diameter, +0.02mm, -0.00 on the inside diameter and +.0.00, -0.005mm on the thickness.  We have original OEM drawings for machines such as NS-Slitter, Areconn, CLG, Yinghe, Haoneng, Sevenstar, Toray, Dali, Korea CIS and Shinjin Mtec.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Lithium-ion%20Carbide%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <div className="row g-1">
              <div className="col my-1">
                <img
                  src={batteries1}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={batteries2}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
              <div className="col my-1">
                <img
                  src={batteries3}
                  alt=""
                  className="col-12 mb-1"
                >
                </img>
                <img
                  src={batteries4}
                  alt=""
                  className="col-12"
                >
                </img>
              </div>
            </div>
            <img
              src={batteries5}
              alt=""
              className="col-12 col-md-12 m-0 px-1"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <br/>
    </>
  )
}

export default Carbide;