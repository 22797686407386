import React from "react";
import { Link } from "react-router-dom";
import TopNav from "./TopNav";
import AllRoutes from "./AllRoutes";
import Footer from "./Footer";
import "./Layout.css";
import logoSilver from "../images/logo-silver.png";

/**
 * Defines the main layout of the application.
 *
 * You will not need to make changes to this file.
 *
 * @returns {JSX.Element}
 */
function Layout() {
    const logo = logoSilver;
  return (
    <main className="content-wrapper">
      <Link
        className="d-flex justify-content-center align-items-center m-0 p-0"
        to="/"
      >
      <img
        src={logo}
        className="img-fluid"
        alt="Silver 'Industrial Knife Warehouse, LLC' logo on black background"
      >
      </img>
      </Link>
      <div className="container-fluid">
        <div className="col h-100">
          <div className="col-12">
            <TopNav />
          </div>
          <div className="content col-12">
            <AllRoutes />
          </div>
          <div className="row bg-light">
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Layout;
