import React from "react";
import home from "../images/home.png";
import "./Home.css"

/**
 * Defines the home page.
 * @returns {JSX.Element}
 */

function Home() {
  const heroImage = home;

  return (
    <main className="py-3">
      <div className="d-flex justify-content-center align-items-center">
        <div className="hero row g-0 col-10">
          <div className="hero-image col-12 col-md-7">
            <img
              src={heroImage}
              alt="A collage of several blades."
              className="img-fluid py-md-4"
            >
            </img>
          </div>
          
          <div className="tagline bg-black col-12 col-md-5 d-flex justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <h1 className="text-white text-center px-4 pf-text my-lg-4">
                INDUSTRIAL KNIFE WAREHOUSE
              </h1>
              <h2 className="text-white text-center px-4 py-2 pf-text my-lg-4">
                A CUT ABOVE THE REST
              </h2>
              <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
                <a
                  className="button button-home"
                  href="mailto:sales@ikwinc.net"
                >
                  GET IN TOUCH
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    <br/>
    </main>
  )
}

export default Home;