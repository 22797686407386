import firstImage from "../../images/shear-blades/shear-blades-1.png";
import secondImage from "../../images/shear-blades/shear-blades-2.png";
import thirdImage from "../../images/shear-blades/shear-blades-3.png";


function ShearBlades() {
  return (
    <>
      <section>
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Shear Blades</h1>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <p className="col-10 col-md-9 text">
            Industrial Knife Warehouse has a large inventory of shear blades stocked in the USA.  When using our shear blades, you will get the most advantageous performance and extended edge life, resulting in more productivity due to less downtime for blade changes.  Our shear blades are manufactured from the finest alloys and are held to a .002” tolerance on the width and thickness from end to end.
          </p>
        </div>
      </section>
      <br/>
      <section className="shear-blades">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="g-3 col-10 col-md-5">
            <div className="col-10 col-md-12 text">
              <h3 className="header-text">High Carbon, High Chrome (HCHC)</h3>
              <br/>
              <p className="mb-4 mb-md-5">
                This wear resistant tool steel is designed for shearing no more than 3/8" thick mild steel on hydraulic shears.When using this grade on mechanically controlled shears, the maximum shearing capacity is no more than 1/4" thick mild steel. When shearing stainless steel, it is recommended that you shear no more than 50% of the machine’s rated cutting capacity.
              </p>
            </div>
            <div className="col-10 col-md-12 text">
              <h3 className="header-text">Shock Resistant</h3>
              <p className="mt-md-4 mb-4 mb-md-3">
                This tool steel provides the highest degree of toughness, recommended for shearing of mild steel 3/8" and thicker. This durable material has increased wearability and is designed for use when shearing heavier gauge material. When shearing stainless steel, it is recommended that you shear no more than 50% of the machine’s rated cutting capacity.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Shear%20Blade%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <div className="col-10 col-md-4">
            <img
              src={firstImage}
              alt="Four shear blades displayed at an angle."
              className="col-12 col-md-9 mb-3"
            >
            </img>
            <img
              src={secondImage}
              alt="Three shear blades displayed at an angle."
              className="col-12 col-md-9 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <section className="rotary-metal-slitters">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="g-3 col-10 col-md-5">
            <div className="col-10 col-md-12 text">
              <h3 className="header-text">Rotary Metal Slitters</h3>
              <br/>
              <p className="mt-md-4 mb-md-3">
                Industrial Knife Warehouse manufactures rotary metal slitters using the finest alloy tool steels and tightest tolerances in the industry.  Each slitter that is manufactured is hardened and tempered to the same hardness throughout the knife which will result with a better cut and longer edge life between regrinds.  All slitters can be manufactured to your specifications with a guaranteed thickness tolerance of ±0.000040.
              </p>
              <div className="d-flex justify-content-center">
                <a
                  type="email"
                  href="mailto:sales@ikwinc.net?subject=Rotary%20Metal%20Slitter%20Quote"
                  className="button button-email mb-3"
                >
                  Get Quote Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-4">
            <img
              src={thirdImage}
              alt="Several circular shear blades displayed in multiple stacks."
              className="col-12 col-md-9 mb-3"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <section className="Brands">
        <div className="d-flex justify-content-center">
          <h2 className="col-10 col-md-9 header-text">Brands</h2>
        </div>
          <br/>
        <div className="d-flex justify-content-center">
          <p className="col-10 col-md-9 text text-center">
            Brands we supply include, but are not limited to: Accurshear / Accupress, Adira Americor, Amada, American Hercules, Atlantic, Betenbender, Century, Cincinnati, Cleveland Crane, Di-acro, Dreis & Krump, Edwards – Besco, Famco, Henry Corp, Herr-Voss, Hydra Tool Corp, Hydrapower, Iowa Precision, Johnson HydraShear, Lodge & Shipley, LVD, Mubea, Murata, National Sheet Metal, Niagara, Pacific, Pearson, Pexto, Procam, Pullmax, Red Bud Industries, Rockwell Australia, Roper-Whitney, Rouselle, Spartanics, Standard, Summit, Tennsmith, Verrina, Verson, Warner Swasey, Weir Corp, Welty-Way, Wysong & Miles
          </p>
        </div>
      </section>
      <br/>
    </>
  )
}

export default ShearBlades;