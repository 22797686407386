import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

function Contact() {

  const initialFormState = {
    name: "",
    email: "",
    message: ""
  }
  const [formData, setFormData] = useState({...initialFormState});
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);
  const form = useRef();

  /*
  * Obtains the date, and converts it to CST/CDT for proper rendering of company hours.
  */
  const fullDate = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" });
  const todayKansas = new Date(fullDate).getDay();
  const workdays = [ 
    {
      id: 1,
      day: "Mon",
      hours: "9:00 am – 5:00 pm",
    },
    {
      id: 2,
      day: "Tue",
      hours: "9:00 am – 5:00 pm",
    },
    {
      id: 3,
      day: "Wed",
      hours: "9:00 am – 5:00 pm",
    },
    {
      id: 4,
      day: "Thu",
      hours: "9:00 am – 5:00 pm",
    },
    {
      id: 5,
      day: "Fri",
      hours: "9:00 am – 5:00 pm",
    },
    {
      id: 6,
      day: "Sat",
      hours: "Closed",
    },
    {
      id: 7,
      day: "Sun",
      hours: "Closed",
    }
  ]
  const weekdays = workdays.map((day) => {
    const id = day.id

    if(todayKansas === id) {
      return (
        <tr key={id} className="fw-bold">
          <td key={id + "a"}>{day.day}</td>
          <td key={id + "b"}>{day.hours}</td>
        </tr>
      )
    } else {
      return (
        <tr key={id}>
          <td key={id + "a"}>{day.day}</td>
          <td key={id + "b"}>{day.hours}</td>
        </tr>
      )
    }
  })

  /*
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=_reCAPTCHA_site_key"
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, [])
  */

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  /*
  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("_reCAPTCHA_site_key_", { action: "homepage" })
        .then(token => {
          setCaptchaToken(token)
        })
    })
  }
  */

  const sendEmail = async (event) => {
    event.preventDefault();
    const Abort = new AbortController();

    try {
      emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(setFormData({...initialFormState}))
    }
    catch (error) {
      console.log(error);
    }
    return () => Abort.abort();
  }

  return (
    <section className="py-3">
      <div className="d-flex justify-content-center align-items-center">
        <div className="row gx-5 col-10">
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-start">
              <h2 className="header-text">Contact Us</h2>
            </div>
            <form className="my-3 text" onSubmit={sendEmail} ref={form}>
              <input
                className="px-2 py-3 my-2"
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                value={formData.name}
              />
              <br/>
              <input
                className="px-2 py-3 mt-1 mb-2"
                id="email"
                type="text"
                name="email"
                placeholder="Email*"
                onChange={handleChange}
                value={formData.email}
                required={true}
              />
              <br/>
              <textarea
                className="p-2 my-3"
                id="message"
                type="text"
                name="message"
                placeholder="Message*"
                onChange={handleChange}
                value={formData.message}
                required={true}
              />
              <br/>
              <button
                id="submit-button"
                type="submit"
                className="button button-home col-12"
              >
                Send
              </button>
              {/*<div
                className="g-recaptcha"
                data-sitekey="_reCAPTCHA_site_key_"
                data-size="invisible"
              ></div>*/}
            </form>
          </div>

          <div className="col-12 col-md-6">
            <h3 className="header-text">Industrial Knife Warehouse</h3>
            <br/>
            <div className="text">
              <p className="address m-0">
                9434 Lake Shore Drive, Nampa, Idaho 83686, United States
              </p>
              <p className="m-0">
                <a
                  href="tel:+12084955546"
                  className="contact"
                >
                  1-208-495-5546
                </a>
              </p>
              <p className="m-0">
                <a
                  href="mailto:bob@ikwinc.net"
                  className="contact"
                >
                  bob@ikwinc.net
                </a>
              </p>
            </div>
            <br/>
            <div className="text">
              <p className="address m-0">
                1350 Prairie Park Lane, Emporia, Kansas 66801, United States
              </p>
              <p className="m-0">
                <a
                  href="tel:+18188599530"
                  className="contact"
                >
                  1-818-859-9530
                </a>
              </p>
              <p className="m-0">
                <a
                  href="mailto:sales@ikwinc.net"
                  className="contact"
                >
                  sales@ikwinc.net
                </a>
              </p>
            </div>
            <br/>
            <div className="text">
              
              <table>
                <thead>
                  <tr>
                    <th className="header-text fw-normal">Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {weekdays}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </section>
  )
}

export default Contact;