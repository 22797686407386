
function Footer() {
  return (
    <footer>
      <p className="text-center fw-bold mt-4">INDUSTRIAL KNIFE WAREHOUSE</p>
      <div className="mt-4">
        <p className="text-center">
          COPYRIGHT © 2023 INDUSTRIAL KNIFE WAREHOUSE - ALL RIGHTS RESERVED.
        </p>
      </div>
    </footer>
  )
}

export default Footer;