import aboutImage from "../images/about.png";

function About() {

  return (
    <main className="py-3">
      <div className="d-flex justify-content-center align-items-center">
        <div className="row gx-4 col-10">
          <h1 className="col-12 mb-2 header-text">About Us</h1>
          <br/>
          <div className="col-12 col-md-8 text">
            <p>
              Founded in 2021, Industrial Knife Warehouse sells high quality industrial knives for the printing, paper converting, packaging, corrugated, plastic recycling and metal industries.  The founder, Bob Lizarraga has been in the knife industry since 1979 which has allowed him to build relationships with the top knife manufacturers all over the world.  You can rest assured that when you deal with Industrial Knife Warehouse, you will be getting the very best blade at the most competitive price.  Our years of experience in the knife industry have helped us solve any complex problems a customer might run into with their cutting applications.
            </p>
            <p>
              Visit our eBay store:&nbsp;
            <a
              href="https://www.ebay.com/usr/industrialkni-0?_trksid=p2047675.m145687.l151931"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Shop Now
            </a>
            </p>
          </div>
          
          <div className="col-12 col-md-4">
              <img
                src={aboutImage}
                className="img-fluid mb-3"
                alt="4 shear blades angled towards the top right">
              </img>
          </div>
        </div>
      </div>
      <br/>
    </main>
  )
}

export default About;