import firstSlitter from "../../images/paper-converting/slitters1.png";
import secondSlitter from "../../images/paper-converting/slitters3.png";
import sheeters from "../../images/paper-converting/sheeters.png";
import holders from "../../images/paper-converting/holders.png";

function PaperConverting() {
  return (
    <>
      <section>
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Paper Converting</h1>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <p className="col-10 col-md-9 text">
            Industrial Knife Warehouse can supply all your cutting needs for the paper converting industry.  These products include top and bottom slitters, sheeter knives and replacement slitter knife holders.  All of our knives are made from D-2  12% chrome, high speed steel, powdered metal or fine grain carbide.
          </p>
        </div>
      </section>
      <br/>
      <section className="slitters">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Slitters</h3>
            <br/>
            <p>
              What sets Industrial Knife Warehouse apart from other knife manufacturers, is the technique that is used to produce our slitters.  All of our slitters are lapped finished to assure a flatness tolerance of .002” or less.  All inside diameters are finished with a honing device that allows us to hold a tolerance of .0005” or less.  All bevels use a special grinding wheel to give one of the finest cutting edges on the market today.  Also, because of our experience in the industry, we are able to produce a slitter that will reduce dust and increase the edge life of the slitter.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Slitter%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <br/>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={firstSlitter}
              alt="Three circular slitter blades."
              className="col-12 col-md-9 mb-3"
            >
            </img>
            <img
              src={secondSlitter}
              alt="A circular bottom slitter."
              className="col-12 col-md-9"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <section className="sheeter-knives">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Sheeter Knives</h3>
            <br/>
            <p>
              Industrial Knife Warehouse supplies high quality sheeter knives made out of the finest materials.  Our sheeter knives are held with un-surpassed tolerances on all critical dimensions such as straightness, parallelism and width.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Sheeter%20Knife%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
                src={sheeters}
                alt="Seven sheeter knives."
                className="col-12 col-md-9 mb-3"
              >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <section className="knife-holders">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-5 text">
            <h3 className="header-text">Knife Holders</h3>
            <br/>
            <p>
              Our aftermarket Tidland knife holders are available for class I, II and III slitters.  These holders will have the same easy assembly as the OEM holder but they are a fraction of the cost.  Replacement parts are sold separately so that you can rebuild the holders when some of the parts start to wear out.
            </p>
            <div className="d-flex justify-content-center">
              <a
                type="email"
                href="mailto:sales@ikwinc.net?subject=Knife%20Holder%20Quote"
                className="button button-email mb-3"
              >
                Get Quote Now
              </a>
            </div>
          </div>
          <div className="images row col-10 col-md-4 justify-content-center">
            <img
              src={holders}
              alt="Two Tidland slitter holders"
              className="col-12 col-md-9"
            >
            </img>
          </div>
        </div>
      </section>
      <br/>
      <br/>
    </>
  )
}

export default PaperConverting;