import firstImage from "../../images/printing/printing1.png";
import secondImage from "../../images/printing/printing2.png";
import thirdImage from "../../images/printing/printing3.png";
import sticksImage from "../../images/printing/cutting-sticks.png";

function Printing() {
  return (
    <>
      <section className="knives">
        <div className="d-flex justify-content-center">
          <h1 className="col-10 col-md-9 header-text">Printing</h1>
        </div>
        <br/>
        <div className="images d-flex justify-content-center align-items-center">
          <div className="row g-3 col-10 col-md-9">
            <img
              src={firstImage}
              alt="A large guillotine-style paper knife."
              className="col-12 col-md-4"
            >
            </img>
            <img
              src={secondImage}
              alt="Three 'perfect binder' trimmer knives."
              className="col-12 col-md-4"
            >
            </img>
            <img
              src={thirdImage}
              alt="Six stitcher knives arrayed in a semicircle."
              className="col-12 col-md-4"
            >
            </img>
          </div>
        </div>
        <br/>
        <div className="col text">
          <div className="d-flex justify-content-center">
            <p className="col-10 col-md-9">
              Industrial Knife Warehouse has a large inventory of paper guillotine knives, perfect binder knives and stitcher knives stocked in the USA.  Our knives for the printing industry are manufactured with the highest standards in the industry.  High alloy steel material combined with a one of a kind brazing and heat treating technology results in longer edge life, straighter knives and overall better performance than any other knife on the market today.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <a
              type="email"
              href="mailto:sales@ikwinc.net?subject=Paper%20Knife%20Quote"
              className="button button-email"
            >
              Get Quote Now
            </a>
          </div>
          <br/>
          <div className="row d-flex justify-content-center">
            <div className="col-10 col-md-3">
              <h3 className="header-text">Standard Inlay Paper Knives</h3>
              <br/>
              <p>
                This knife consists of a standard steel body with a high alloy tool steel insert at the cutting edge to produce an economically priced, high quality knife. This knife will average around 1,200 cuts before needing to be resharpened.
              </p>
            </div>
            <div className="col-10 col-md-3">
              <h3 className="header-text">High Speed Steel Paper Knives</h3>
              <br/>
              <p>
                This knife consists of a standard steel body with an 18% tungsten, high speed steel insert at the cutting edge to produce a longer lasting, straighter cutting knife than the standard inlay paper knife. This knife will average 3,000 - 4,000 cuts before needing to be resharpened.
              </p>
            </div>
            <div className="col-10 col-md-3">
              <h3 className="header-text">Carbide "Elite" Paper Knives</h3>
              <br/>
              <p>
                This knife consists of a standard steel body with a new generation, fine grain carbide insert at the cutting edge to produce the longest lasting paper knife on the market today. This knife on average will last 8-10 times longer than a high speed steel knife but we have had some customers comment that it lasts up to 30 times longer.
              </p>
            </div>
          </div>
          <br/>
          <div className="d-flex justify-content-center">
            <h3 className="header-text col-10 col-md-9">Brands</h3>
          </div>
          <div className="d-flex justify-content-center">
            <p className="col-10 col-md-9">
              Brands we supply include, but are not limited to: Baumfolder, Challenge, Chandler & Price, Consolidated, Duplo, Heidelberg, Horizon, Imperial, Itoh, Kolbus, Lawson, Macy, Martin Yale, McCain, Michael Business Machines, Muller Martini, Perfecta, Pivano, Polar, Prism, Pro-Cut, Saber, Seybold, Sheridan, Stahl, and more
            </p>
          </div>
          <br/>
        </div>
      </section>
      <br/>
      <section className="sticks">
        <div className="d-flex justify-content-center">
          <h3 className="header-text col-10 col-md-9">Cutting Sticks</h3>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <img
            src={sticksImage}
            alt="Details for available cutting stick hardnesses and sizes."
            className="col-8"
          >
          </img>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
          <p className="text col-10 col-md-9">
            Industrial Knife Warehouse also stocks a large amount of cutting sticks.  Our cutting sticks are manufactured from specially formulated thermoplastic compounds and can drastically increase your production and extend the life of your knife when used correctly.  We hold our cutting sticks to a very tight tolerance to ensure they fit on your machine correctly.
          </p>
        </div>
      </section>
      <br/>
    </>
  )
}

export default Printing;